import PropTypes from 'prop-types';

import HelpersController from './../HelpersController';
import {LayersCameras, LayersDifferentials, LayersPins, LayersZoom} from '../layers';
import {StagesPins, StagesViews} from './';
import {GamesGenius} from './../games';
import {HelpersKeyboard} from "../index";

let _this = {};

/**
 *
 */
class StagesPaginates extends HelpersController {
    /**
     *
     * @param props
     * @param main
     */
    constructor(props, main) {
        super(props);

        this.props = props;
        this.main = main;

        _this = this;
    };

    /**
     *
     * @returns {*}
     */
    get = () => {
        const {current_name} = this.props;

        return this.main.getState(`_this_${current_name}`);
    };

    /**
     *
     * @returns {string}
     */
    occurrence = () => {
        const {currentFrame} = this.get();

        return this.padWithLeadingZeros(currentFrame, 2);
    };

    /**
     *
     * @returns {*}
     */
    getFrame = () => {
        return this.get()[`occ_frame_${this.occurrence()}`];
    };

    /**
     *
     */
    iconPage = () => {
        const
            {disable_icon_page} = this.props,
            _this_current = this.get();

        _this_current[`occ_icon_${disable_icon_page}`].occ_round_expand.round_animate.visible = false;
    };

    /**
     *
     */
    iconFrame = () => {
        const
            _this_current = this.get(),
            {totalFrames, currentFrame} = _this_current;

        for (let i = 0; i < (totalFrames - 1); i++) {
            let index = (i + 1);

            _this_current[`occ_icon_frame_${this.padWithLeadingZeros(index, 2)}`].occ_round_expand.round_animate.visible = (index.toString() !== currentFrame.toString());
        }
    };

    /**
     *
     */
    iconPaginate = () => {
        const _this_current = this.get();

        let
            occ_icon_more = this.verifyField(_this_current, 'occ_icon_more'),
            occ_icon_less = this.verifyField(_this_current, 'occ_icon_less'),
            occ_icon_prev = this.verifyField(_this_current, 'occ_icon_prev'),
            occ_icon_next = this.verifyField(_this_current, 'occ_icon_next');

        if (occ_icon_more) {
            occ_icon_more.occ_more.visible = true;
            occ_icon_more.occ_less.visible = false;
        }

        if (occ_icon_less) {
            occ_icon_less.occ_more.visible = false;
            occ_icon_less.occ_less.visible = true;
        }

        if (occ_icon_prev) {
            occ_icon_prev.occ_more.visible = false;
            occ_icon_prev.occ_prev.visible = true;
        }

        if (occ_icon_next) {
            occ_icon_next.occ_more.visible = false;
            occ_icon_next.occ_next.visible = true;
        }
    };

    /**
     *
     * @param frame
     * @param effect
     */
    navigate = (frame, effect = false) => {
        const
            {
                disable_icon_page,
                contain_icon_frame,
                contain_cameras,
                contain_differentials,
                enabled_differentials,
                contain_pins,
                contain_zoom
            } = this.props,
            _this_current = this.get();

        _this_current.gotoAndStop(frame);

        disable_icon_page && this.iconPage();
        contain_icon_frame && this.iconFrame();
        (contain_cameras && this.isObjectIf(this.$LayersCameras)) && this.$LayersCameras.close();
        (contain_differentials && this.isObjectIf(this.$LayersDifferentials)) && (Boolean(enabled_differentials) ? this.$LayersDifferentials.show() : this.$LayersDifferentials.close());
        (contain_pins && this.isObjectIf(this.$StagesPins)) && this.$StagesPins.close();
        (contain_zoom && this.isObjectIf(this.$LayersZoom)) && this.$LayersZoom.destroy();

        effect && this.main.effectFadeIn(_this_current, `occ_frame_${this.occurrence()}`);
    };

    /**
     *
     */
    first = () => {
        const {
            default_frame,
            contain_icon_paginate,
            current_name,
            contain_cameras,
            contain_differentials,
            enabled_differentials,
            contain_pins,
            contain_pins_library,
            contain_layer_pins,
            contain_views,
            contain_zoom,
            contain_zoom_window,
            contain_game_genius,
            contain_keyboard,
            contain_keyboard_window
        } = this.props;

        this.navigate(default_frame);

        /**
         * The handler is executed at most once.
         */
        contain_icon_paginate && this.iconPaginate();

        if (contain_cameras) {
            this.$LayersCameras = LayersCameras(this.main)({current_name: current_name});
            this.$LayersCameras.create();
        }

        if (contain_differentials) {
            this.$LayersDifferentials = LayersDifferentials(this.main)({current_name: current_name});
            this.$LayersDifferentials.create(Boolean(enabled_differentials));
        }

        if (contain_pins) {
            let options = {current_name: current_name};

            !this.isEmpty(contain_pins_library) && (options = {...options, library: contain_pins_library});

            this.$StagesPins = StagesPins(this.main)(options);
            this.$StagesPins.create();
        }

        if (contain_layer_pins) {
            this.$LayersPins = LayersPins(this.main)({current_name: current_name});
            this.$LayersPins.create();
        }

        if (contain_views) {
            this.$StagesViews = StagesViews(this.main)({current_name: current_name});
            this.$StagesViews.create();
        }

        if (contain_zoom) {
            let options = {current_name: current_name};

            this.isObjectIf(contain_zoom_window) && (options = {...options, window: contain_zoom_window});

            this.$LayersZoom = LayersZoom(this.main)(options);
            this.$LayersZoom.create();
        }

        if (contain_game_genius) {
            this.$GamesGenius = GamesGenius(this.main)({current_name: current_name});
            this.$GamesGenius.create();
        }

        if (contain_keyboard) {
            let options = {current_name: current_name};

            this.isObjectIf(contain_keyboard_window) && (options = {...options, window: contain_keyboard_window});

            this.$HelpersKeyboard = HelpersKeyboard(this.main)(options);
            this.$HelpersKeyboard.create();
        }
    };

    /**
     *
     * @param event
     */
    paginate = (event) => {
        let {name} = event.target;

        this.navigate(`frame${this.padWithLeadingZeros(name.substring(name.length - 2), 2)}`, true);
    };

    /**
     *
     */
    create = () => {
        const
            _this_current = this.get(),
            {totalFrames} = _this_current;

        this.first();

        /**
         *
         *  Add event in button for paginate in frame.
         */
        for (let i = 0; i < (totalFrames - 1); i++) {
            let occurrence = this.padWithLeadingZeros((i + 1), 2);

            if(this.verifyField(_this_current, `btn_frame_${occurrence}`)){
                this.removeListener('click', `paginate-${occurrence}`, this.paginate, () => {
                    this.addListener(_this_current[`btn_frame_${occurrence}`], 'click', `paginate-${occurrence}`, this.paginate);
                });
            }
        }
    };
}

/**
 *
 * @type {{disable_icon_page: Requireable<string>, contain_views: Requireable<boolean>, contain_icon_paginate: Requireable<boolean>, contain_icon_frame: Requireable<boolean>, contain_zoom: Requireable<boolean>, contain_cameras: Requireable<boolean>, default_frame: Requireable<string>, contain_pins_library: Requireable<object>, contain_differentials: Requireable<boolean>, name: Requireable<string>, contain_pins: Requireable<boolean>, contain_markers: Requireable<boolean>, current_name: Requireable<string>, contain_layer_pins: Requireable<boolean>, contain_zoom_window: Requireable<object>}}
 */
const propTypes = {
    name: PropTypes.string,
    default_frame: PropTypes.string,
    current_name: PropTypes.string,
    disable_icon_page: PropTypes.string,
    contain_icon_frame: PropTypes.bool,
    contain_icon_paginate: PropTypes.bool,
    contain_cameras: PropTypes.bool,
    contain_differentials: PropTypes.bool,
    contain_pins: PropTypes.bool,
    contain_pins_library: PropTypes.string,
    contain_layer_pins: PropTypes.bool,
    contain_markers: PropTypes.bool,
    contain_views: PropTypes.bool,
    contain_zoom: PropTypes.bool,
    contain_zoom_window: PropTypes.object,
    contain_game_genius: PropTypes.bool
};

/**
 *
 * @type {{default: {disable_icon_page: null, contain_views: boolean, contain_icon_paginate: boolean, contain_icon_frame: boolean, contain_zoom: boolean, contain_cameras: boolean, default_frame: string, contain_pins_library: {}, contain_differentials: boolean, name: string, contain_pins: boolean, contain_markers: boolean, current_name: null, contain_layer_pins: boolean, contain_zoom_window: {}}}}
 */
const config = {
    default: {
        name: 'paginates',
        default_frame: 'frame01',
        current_name: null,
        disable_icon_page: null,
        contain_icon_frame: false,
        contain_icon_paginate: false,
        contain_cameras: false,
        contain_differentials: false,
        contain_pins: false,
        contain_pins_library: null,
        contain_layer_pins: false,
        contain_markers: false,
        contain_views: false,
        contain_zoom: false,
        contain_zoom_window: {},
        contain_game_genius: false,
        contain_keyboard: false
    }
};

/**
 *
 * @param _this
 * @returns {function(*): StagesPaginates}
 */
export default (_this) => (props) => {
    const defaultProps = {...config.default, ...props};
    PropTypes.checkPropTypes(propTypes, defaultProps, 'prop', 'StagesPaginates');
    return (new StagesPaginates(defaultProps, _this));
};